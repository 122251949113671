import { useEffect } from "react";

import languageStore, { defaultLanguage } from "@stores/language.store";

export const useLanguageStore = () => {
  const { currentLanguage, languageOptions, setLanguage } = languageStore();

  useEffect(() => {
    (async () => {
      const storedLanguage = localStorage.getItem("language") || defaultLanguage;
      await setLanguage(storedLanguage);
    })();
  }, [setLanguage]);

  return { currentLanguage, languageOptions, setLanguage };
};
