import { ReactNode } from "react";
import { Popover } from "@mui/material";
import { Box } from "@mui/system";

type SettingPopoverProps = {
  id?: string;
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  children: ReactNode;
};

function SettingPopover({
  id = "",
  open = false,
  anchorEl = null,
  handleClose,
  children,
}: SettingPopoverProps) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transitionDuration={200}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          overflow: "unset",
        },
      }}
    >
      <Box sx={{ minWidth: "360px" }}>{children}</Box>
    </Popover>
  );
}

export default SettingPopover;
