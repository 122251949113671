import { create, type StateCreator } from "zustand";

import LANGUAGES from "@config/i18n/langs.json";
import i18n from "@core/i18n";
import { zustandMiddlware } from "@core/stores/middleware";

export const defaultLanguage = "en";

type State = {
  currentLanguage: string;
};

type Action = {
  setLanguage: (newLanguage: string) => Promise<void>;
  languageOptions: Map<string, string>;
};

type LanguageStore = State & Action;

const languageStore: StateCreator<
  LanguageStore,
  [["zustand/persist", unknown]],
  [],
  LanguageStore
> = (set) => ({
  currentLanguage: defaultLanguage,
  languageOptions: new Map(LANGUAGES),

  setLanguage: async (newLanguage) => {
    await i18n.changeLanguage(newLanguage);
    set({ currentLanguage: newLanguage });
    localStorage.setItem("language", newLanguage);
  },
});

export default create<LanguageStore>()(
  zustandMiddlware(languageStore, {
    name: "languageStore",
  })
);
