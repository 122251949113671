import type { PropsWithChildren } from "react";
import { Box, Grid, SxProps, Tooltip, Typography } from "@mui/material";

import { formatNumberForUnitMainLabel } from "@core/utils";

import Peak from "../../../../icons/Peak";
import ArrowDeviation from "../../ArrowDeviation";
import CardBottomText from "../../CardBottomText";
import { LargeNumber } from "../../LargeNumber";
import BaseCard from "../BaseCard/BaseCard";

/**
 * - This card is built on top of the BaseCard component.
 * - The card displays a number with a unit.
 * - The unit is displayed in the right side of the value.
 * - The card can take a subtitle, displayed below the value.
 * - The card can also take an exponential value.
 * - The card show peak value and icon tooltip on the left .
 * - The card show trend value and icon (base on the value )tooltip on the right .
 */
export const InfoCard = ({
  title,
  titleAddon,
  subtitle,
  value,
  unit,
  loading,
  error,
  errorMessage,
  color = "secondary",
  size,
  peak = { value: null },
  trend = { value: null },
  testId,
  footer = null,
  noTitleShadow = false,
  sx = {},
}: InfoCardProps) => {
  let formattedValue = value;
  let formattedUnit = unit;
  let formattedExp = 0;
  let formattedPrefix = "";

  // If it is a number and the unit is not empty, format the value and unit.
  if (!loading && typeof value === "number" && unit) {
    [formattedValue, formattedExp, formattedPrefix, formattedUnit] = formatNumberForUnitMainLabel(
      value,
      unit
    );
  }
  let refNumber = "";
  let refPrefix = "";
  let refUnit = "";

  [refNumber, , refPrefix, refUnit] = formatNumberForUnitMainLabel(peak.value, "kW");

  const largeNumTooltip = unit
    ? `${new Intl.NumberFormat("se-SE").format(Number(value))} ${unit}`
    : value;

  return (
    <BaseCard
      title={title}
      titleAddon={titleAddon}
      loading={loading}
      error={error}
      errorMessage={errorMessage}
      testId={testId}
      minHeight={210}
      noTitleShadow={noTitleShadow}
      sx={sx}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="stretch"
        width="100%"
        height="100%"
        px={2}
      >
        {/* Header */}
        <div>{/* This is just mock to that the space-between works. */}</div>
        {/* Content */}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <LargeNumber
            value={formattedValue}
            unit={formattedPrefix ? formattedPrefix + formattedUnit : formattedUnit}
            exp={formattedExp}
            color={color}
            size={size}
            tooltip={String(largeNumTooltip)}
          />
          <Typography variant={size === "small" ? "caption" : "subtitle2"} color={color}>
            {subtitle ?? ""}
          </Typography>
        </Box>
        {/* Footer */}
        <Grid container pb={1}>
          {footer ? (
            <Grid item xs={12} justifyContent="flex-start">
              {footer}
            </Grid>
          ) : (
            <>
              <Grid item xs={6} justifyContent="flex-start">
                {peak.title && peak.value ? (
                  <CardBottomText
                    startIcon={
                      <Tooltip title={peak.title}>
                        <Box>
                          <Peak fontSize="large" />
                        </Box>
                      </Tooltip>
                    }
                    value={refNumber}
                    suffix={refPrefix + refUnit}
                  />
                ) : null}
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end" maxHeight={100}>
                {trend.title && (
                  <Tooltip title={trend.title}>
                    <ArrowDeviation color={color} value={trend.value} suffix={trend.suffix} />
                  </Tooltip>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </BaseCard>
  );
};

export default InfoCard;

type InfoCardProps = {
  title?: string | React.ReactNode;
  titleAddon?: React.ReactNode;
  subtitle?: string | null;
  value?: string | number | null;
  unit?: string;
  loading?: boolean;
  error?: boolean;
  errorMessage?: string | null;
  color?: string;
  size?: "default" | "small";
  peak?: { title?: string; value: number | null };
  trend?: { title?: string; value?: number | null; suffix?: string };
  testId?: string;
  footer?: React.ReactNode;
  noTitleShadow?: boolean;
  sx?: SxProps;
} & PropsWithChildren;
