import { useEffect } from "react";
import { Grid } from "@mui/material";

import { UNIT } from "@config";
import { logger as baseLogger, useNetworkStore } from "@core";
import { PropertyDetail } from "@modules/PropertyDetail";
import useDashboardStore from "@stores/dashboard.store";
import { HeadingBar, InfoBlockGrid, InfoCard } from "@shared";

import DeviationCard from "./DeviationCard";

const logger = baseLogger.getSubLogger({ name: "DashboardModule" });

export default function DashboardModule() {
  const networkReady = useNetworkStore((s) => s.ready);
  const selectedNetworkId = useNetworkStore((s) => s.selectedNetworkId);
  const activeYear = useNetworkStore((s) => s.activeYear);
  const { tableColumns, rows, month, ready, fetchData } = useDashboardStore();
  const loading = !ready || !networkReady;

  // demo purpose only. to be removed.
  const firstSubstation = rows[0];

  useEffect(() => {
    if (!networkReady || !selectedNetworkId) return;
    void fetchData();
  }, [fetchData, networkReady, selectedNetworkId]);

  logger.trace("RENDER", { loading, columns: tableColumns.length });
  return (
    <Grid container direction="row" spacing={2} p={2}>
      <Grid item xs={12}>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <HeadingBar title="ALL PROPERTIES" />
      </Grid>
      <Grid item xs={4}>
        <InfoCard
          title={`Energy consumption ${activeYear}`}
          value={firstSubstation?.core.heat_energy_sum}
          unit={UNIT.energy}
          loading={loading}
          trend={{
            title: "previous year",
            value: 2.4,
            suffix: "%",
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <DeviationCard
          title="Deviation from forecast"
          titleAddon="(Energy, MWh)"
          loading={loading}
          rows={[
            {
              address: "SKARPENESET 26",
              value: 16.0,
              change: 10.1,
            },
            {
              address: "PILEVEIEN 15",
              value: 2.38,
              change: 9.4,
            },
            {
              address: "HOLMÅSEN 11",
              value: 1.78,
              change: 7.1,
            },
            {
              address: "TREGDEVEIEN 1097",
              value: 2.17,
              change: 6.2,
            },
            {
              address: "DONEHEIA 75",
              value: 2.51,
              change: -6.1,
            },
          ]}
        />
      </Grid>
      <Grid item xs={4}>
        <DeviationCard
          title="Deviation from forecast"
          titleAddon="(Power, kW)"
          loading={loading}
          rows={[
            {
              address: "DYRSTADVEIEN 104",
              value: 5.17,
              change: 10.1,
            },
            {
              address: "HØKSÅSSTRANDA 5",
              value: 4.65,
              change: 9.4,
            },
            {
              address: "PILEVEIEN 15",
              value: 6.7,
              change: 9.1,
            },
            {
              address: "TREGDEVEIEN 1097",
              value: 6.57,
              change: 8.4,
            },
            {
              address: "KROKVEIEN 1",
              value: 3.96,
              change: -8.3,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <InfoBlockGrid
          columns={tableColumns}
          data={[firstSubstation]}
          year={activeYear}
          month={month}
          loading={loading}
          stickyHeader
          enableFacetedValues={false}
        />
      </Grid>

      {/* Property detail overlay */}
      <PropertyDetail />
    </Grid>
  );
}
