import { Drawer } from "@mui/material";
import { useStore } from "zustand";

import { APP_BAR_HEIGHT } from "@config";
import { useNetworkStore } from "@core";

import { PropertyDetailModule } from "./PropertyDetail.module";

export function PropertyDetail() {
  const selectedSubstationId = useStore(useNetworkStore, (s) => s.selectedSubstationId);
  const setSelectedSubstationId = useStore(useNetworkStore, (s) => s.setSelectedSubstationId);

  const onCloseProperty = () => {
    setSelectedSubstationId(undefined);
  };

  return (
    <Drawer
      open={!!selectedSubstationId}
      onClose={onCloseProperty}
      hideBackdrop
      PaperProps={{
        sx: {
          width: "100%",
          top: `${APP_BAR_HEIGHT}px`,
        },
      }}
      ModalProps={{
        slotProps: {
          root: {
            style: {
              top: `${APP_BAR_HEIGHT}px`,
            },
          },
        },
      }}
      sx={{
        "& .MuiDrawer-paper": {
          position: "unset",
        },
      }}
    >
      <PropertyDetailModule />
    </Drawer>
  );
}
