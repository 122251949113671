import { Paper, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";

import { UNIT } from "@config";
import { BaseCard, HighchartsBase, InfoCard } from "@shared";

type SensorData = {
  [key in "consumption" | "returnTemp"]: {
    value?: number;
    trend?: number;
    monthly?: MonthlyData;
  };
};

const blocks = {
  energy: "heat_energy_sum",
  returnTemp: "returntemp_flowweighted_avg",
};

const categories = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

type Addon = {
  text?: string;
  action?: React.ReactNode;
};
type CardHeaderProps = {
  title: string;
  addon: Addon;
};

const CardHeader = ({ title, addon }: CardHeaderProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        backgroundColor: "none",
      }}
    >
      <Typography>{title}</Typography>
      {addon?.text && <Typography>{addon.text}</Typography>}
      {addon.action && <Box>{addon.action}</Box>}
    </Box>
  );
};

export function PropertyDetailEnergyData() {
  return (
    <Box width="100%">
      <Paper elevation={1} sx={{ backgroundColor: "#eff5f6", p: "1.15rem", borderRadius: "10px" }}>
        <CardHeader
          title="Energy consumption overview"
          addon={{ text: "Not effected by Settings" }}
        />
        <Box display="flex" pt={2}>
          <InfoCard
            title="Total consumption (last 12 months)"
            value={284}
            unit="kWh"
            trend={{
              title: "text_normalized_yearly_consumption_desc",
              value: 53,
              suffix: "Wh",
            }}
            noTitleShadow
            color="#dd3b00"
            sx={{ borderRadius: "10px" }}
          />
        </Box>
      </Paper>
    </Box>
  );
}
