import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";

import { App, onRedirectCallback } from "./App";

// Render the app
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const domain = import.meta.env.REACT_APP_PROPAI_AUTH0_DOMAIN;
const clientId = import.meta.env.REACT_APP_PROPAI_AUTH0_CLIENTID;
const audience = import.meta.env.REACT_APP_AUTH0_AUDIENCE;

root.render(
  <StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
      // @ts-expect-error ignore
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>
  </StrictMode>
);
