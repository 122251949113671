import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  AppBar,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { faChevronLeft, faGlobe, faRightFromBracket } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";

import { getNetworksQueryOptions, logger, useNetworkStore } from "@core";
import i18n from "@core/i18n";
import { LanguageSelect } from "@components/ui/LanguageSelection";
import SettingPopover from "@components/ui/SettingPopover";
import { usePropertyStore } from "@stores/property.store";
import { useLocalStorage } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared";
import { LoaderScreen } from "@shared/LoaderScreen";
import * as CONSTANTS from "@shared/ui/layout/constants";

import { useLanguageStore } from "../../lib/hooks/useLanguageStore";

const pageTitle = "PropAI";

export const AppLayout = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { auth } = Route.useRouteContext();

  const { ready, error: networkError, selectedNetworkId, selectNetwork } = useNetworkStore();
  const [defaultNetwork, setDefaultNetwork] = useLocalStorage("defaultNetwork", "");

  const setPropertyId = useNetworkStore((s) => s.setSelectedSubstationId);
  const selectedSubstationId = useNetworkStore((s) => s.selectedSubstationId);
  const refetch = usePropertyStore((s) => s.refetch);

  const onCloseProperty = () => {
    setPropertyId(undefined);
    refetch();
  };

  useEffect(() => {
    if (ready || selectedNetworkId) return;
    (async () => {
      const currentNetwork = await selectNetwork(String(defaultNetwork));
      // save selected network to browser store
      if (currentNetwork && !defaultNetwork) setDefaultNetwork(currentNetwork);
    })();
  }, [ready, defaultNetwork, selectNetwork, setDefaultNetwork, selectedNetworkId]);

  const outletRenderer = ready ? <Outlet /> : <LoaderScreen />;

  logger.debug("RENDER <AppLayout> %j", { ready, selectedNetworkId });

  const { currentLanguage, languageOptions, setLanguage } = useLanguageStore();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPopoverAnchorEl(null);
  };

  const handleConfirmLanguage = async () => {
    await i18n.changeLanguage(currentLanguage);
    localStorage.setItem("language", currentLanguage);
    handleClose();
  };

  return (
    <>
      <AppBar
        elevation={0}
        position="static"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: "primary.dark",
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          color: "white",
          height: "5.14rem",
          py: 1,
        }}
      >
        <Toolbar>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item flex={1}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img src="/hafslund.webp" alt="Hafslund | PropAI" height="32px" />
                <Typography
                  variant="h4"
                  data-testid="page-title"
                  fontSize={32}
                  fontWeight={400}
                  fontFamily="Roboto"
                  ml={2}
                >
                  {pageTitle}
                </Typography>
              </Box>
            </Grid>
            {/* Language Selection */}
            <Grid item>
              <IconButton sx={{ columnGap: 1 }} size="small" onClick={handleClick}>
                <FontAwesomeIcon color="white" icon={faGlobe} />
                <Typography color="white" sx={{ textTransform: "capitalize" }}>
                  {currentLanguage}
                </Typography>
              </IconButton>
            </Grid>
            {/* Logout */}
            <Grid item>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Tooltip title="Logout">
                  <IconButton size="small" onClick={() => auth?.logout()}>
                    <FontAwesomeIcon color="white" icon={faRightFromBracket} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            {selectedSubstationId && (
              <Grid item>
                <IconButton color="inherit" onClick={onCloseProperty}>
                  <FontAwesomeSvgIcon icon={faChevronLeft} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>

      {/* Language option renderer */}
      <SettingPopover
        id="language-option"
        open={!!popoverAnchorEl}
        anchorEl={popoverAnchorEl}
        handleClose={handleClose}
      >
        <Box display="flex" flexDirection="column" width="100%" px={2} pb={2} pt={3} gap={1.25}>
          <Box display="inherit" alignItems="center" gap={1}>
            <FontAwesomeSvgIcon icon={faGlobe} sx={{ width: "20px", height: "20px" }} />
            <Typography variant="caption">{t("text_language", { ns: "_common" })}:</Typography>
          </Box>
          <LanguageSelect
            value={currentLanguage}
            options={languageOptions}
            onChange={setLanguage}
            width={CONSTANTS.DROPDOWN_WIDTH}
          />
        </Box>
        <Box px={2} pb={3} pt={2} display="flex" justifyContent="space-between">
          <Button
            sx={{ width: "140px", height: "30px" }}
            variant="contained"
            color="primary"
            onClick={handleConfirmLanguage}
          >
            {t("action_confirm", { ns: "_action" })}
          </Button>
          <Button
            sx={{ width: "140px", height: "30px" }}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            {t("action_cancel", { ns: "_action" })}
          </Button>
        </Box>
      </SettingPopover>

      {/* Main/Route Content */}
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100vh",
          color: "grey.800",
        }}
      >
        {/* Body */}
        <Box
          component="section"
          height="100%"
          overflow="auto"
          flexGrow={1}
          display="flex"
          flexDirection="column"
        >
          {networkError ? (
            <Box p={4}>
              <Alert severity="error">
                <AlertTitle>{networkError.message}</AlertTitle>
                This likely means you dont have access to any network. Please contact your admin.
              </Alert>
            </Box>
          ) : (
            outletRenderer
          )}
        </Box>
      </Box>
    </>
  );
};

export const Route = createFileRoute("/app/_layout")({
  component: AppLayout,
  // Before loading, authenticate the user via our auth context
  // This will also happen during prefetching (e.g. hovering over links, etc)
  beforeLoad: ({ context, location }) => {
    // If the user is logged out, redirect them to the login page
    if (!context.auth?.isAuthenticated && !context.auth?.isLoading) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
  // Fancy little piece of code that ensures the networks data is loaded,
  // before rendering the component
  loader: async ({ context }) => {
    try {
      const networks = await context.queryClient.ensureQueryData(getNetworksQueryOptions());
      useNetworkStore.getState().setNetworks(networks);
    } catch (error) {
      logger.error("Error loading networks", error);
    }
  },
});
